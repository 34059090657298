<template>
  <div class="container">
    <b-overlay :show="loading" rounded="sm">
      <form @submit.prevent="proyectoId ? handleUpdateDesarrollo() : handleAddDesarrollo()">
        <div class="row col-12">
          <UIInput v-model="proyecto.nombre" 
          label="Nombre" required :key="keys.nombre" />
          <UIInput v-model="proyecto.nombreIngles" 
          label="Nombre inglés" required :key="keys.nombreIngles" />
          <UIInput v-model="proyecto.direccion" 
          label="Direccion" required :key="keys.direccion" />
          <UIInput v-model="proyecto.ciudad" 
          label="Ciudad" required :key="keys.ciudad" />
          <UIInput v-model="proyecto.pais" 
          label="Pais" required :key="keys.pais" />
          <SeleccionarCategoria v-model="proyecto.categoria"
          :key="keys.categoria" />
          <UIInput v-model="proyecto.giro" 
          label="Giro" required :key="keys.giro" />
          <UIInput v-model="proyecto.giroIngles" 
          label="Giro inglés" required :key="keys.giroIngles" />
        </div>
        <UIEditor label="Descripcion"
        v-model="proyecto.descripcion" :key="keys.descripcion" />
        <UIEditor label="Descripcion inglés"
        v-model="proyecto.descripcionIngles" :key="keys.descripcionIngles" />
        <div class="row col-12">
          <h5 class="mt-3">Galeria</h5>
        </div>
        <div class="row col-12" :key="formInputkey">
          <GaleriaSeleccionar v-model="imagen"
          @imageUploaded="imageUploaded($event)" />
          <GaleriaImagenes :removeImageLocal="removeImageLocal"
          :removeImageDb="removeImageDb" :galeria="proyecto.galeria" />
        </div>
        <BotonGuardar :id="proyectoId" />
      </form>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {PROYECTO, PROYECTO_KEYS} from '@/constants/proyecto'
import {
  incrementPositionAndRemove,
  getHighestPositionInArray,
} from "@/helpers/arrays";
import UIInput from '@/components/ui/input'
import UIEditor from '@/components/ui/editor'
import SeleccionarCategoria from '@/components/seleccionar/categoria'
import BotonGuardar from '@/components/boton/guardar'
import GaleriaImagenes from '@/components/galeria/imagenes'
import GaleriaSeleccionar from '@/components/galeria/seleccionar'

export default {
  props: {
    proyectoId: {
      type: String,
      required: false,
    },
  },
  components: {
    UIInput,
    SeleccionarCategoria,
    UIEditor,
    BotonGuardar,
    GaleriaImagenes,
    GaleriaSeleccionar,
  },
  data() {
    return {
      loading: false,
      formInputkey: 0,
      position: 1,
      imagen: null,
      proyecto: JSON.parse(JSON.stringify(PROYECTO)),
      keys:JSON.parse(JSON.stringify(PROYECTO_KEYS)),
    };
  },
  async mounted() {

    if (!this.proyectoId) return
    
    await this.getProyecto(this.proyectoId);
    Object.keys(this.keys).forEach(k =>{
      this.keys[k]++
    })
    this.position = getHighestPositionInArray(this.proyecto.galeria) + 1;
    this.proyecto = JSON.parse(JSON.stringify(this.proyectodb));

  },
  computed: {
    ...mapGetters({
      proyectodb: "proyectos/proyecto",
    }),
  },
  methods: {
    ...mapActions({
      saveProyecto: "proyectos/saveProyecto",
      getProyecto: "proyectos/getProyecto",
      deleteProyectoImage: "proyectos/deleteProyectoImage",
      updateProyecto: "proyectos/updateProyecto",
    }),
    imageUploaded(imagen) {
      let url = URL.createObjectURL(imagen);
      this.proyecto.galeria.push({ imagen, posicion: this.position, url });
      this.imagen = null;
      this.position++;
      this.formInputkey++;
    },
    async handleAddDesarrollo() {
      this.loading = true;
      const res = await this.saveProyecto(this.proyecto);
      
      if (res) {
        this.$swal("Exito", "Desarrollo creado", "success");
        this.loading = false;
        return this.$router.push({ name: "Admin Proyectos" });
      }

      this.$swal("Error", "Error al crear el desarrollo", "error");
      this.loading = false;

    },
    async handleUpdateDesarrollo() {

      this.loading = true;
      const res = await this.updateProyecto(this.proyecto);

      if (res) {
        this.$swal("Exito", "Proyecto actualizado", "success");
        this.loading = false;
        return this.$router.push({ name: "Admin Proyectos" });
      }

      this.$swal("Error", "Error al actualizar el proyecto", "error");
        this.loading = false;

    },
    removeImageLocal(index) {
      this.proyecto.galeria = incrementPositionAndRemove(
        this.proyecto.galeria,
        index
      );
      this.position--;
    },
    async removeImageDb(image, index) {
      const res = await this.deleteProyectoImage(image);
      if (res) {
        this.$swal("Exito", "Imagen eliminada", "success");
        this.proyecto.galeria.splice(index, 1);
        return 
      }
      this.$swal("Error", "Error al eliminar la imagen", "error");
    },
  },
};
</script>