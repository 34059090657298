<template>
  <form-proyecto></form-proyecto>
</template>

<script>
import formProyecto from "@/components/proyectos/formProyecto.vue";
export default {
  components: {
    formProyecto,
  },
};
</script>

<style></style>
